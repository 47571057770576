import React, { useEffect, useState } from 'react';
import './PeopleInOffice.scss';
import { Trans } from 'react-i18next';

const PeopleInOffice = ({ countUsers, usersAvatar }) => {
  const [state, setState] = useState(0);

  useEffect(() => {
    setState('');
    const timer = setTimeout(() => {
      setState('active');
    }, 1000);
    return () => clearTimeout(timer);
  }, [usersAvatar]);

  return (
    <div className="people">
      {usersAvatar && usersAvatar.length ? (
        <>
          <div className="people__title">
            <Trans i18nKey="people_in_office" values={{ count: countUsers }} />
          </div>
          <div className="people__avatars avatars">
            <div className="avatars__list">
              {usersAvatar.map((avatar, i) => (
                <div className="avatars__item" key={`avatar${i}`}>
                  <img
                    src={avatar}
                    className={`${state}`}
                    alt="avatar"
                    style={{ transition: `opacity ${Math.floor(Math.random() * 4)}s` }}
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PeopleInOffice;
