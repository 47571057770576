import React, { useEffect, useState } from 'react';
import format from 'date-fns/format';
import './TodayDateTime.scss';
import set from 'date-fns/set';

const TodayDateTime = ({ darkTheme }) => {
  const todayDate = new Date();
  const [time, setTime] = useState('');
  const day = format(todayDate, 'EEEE, LLLL d');

  useEffect(() => {
    const interval = setInterval(setClock, 1000);
    return () => clearInterval(interval);
  }, []);
  const setClock = () => {
    const date = new Date();
    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const result = set(new Date(), { hours: hours, minutes: minutes });
    setTime(format(result, 'h:mm aa'));
  };

  return (
    <div className={`today ${darkTheme ? 'today--dark' : 'today--light'}`}>
      <div className="today__time">{time}</div>
      <div className="today__date">{day}</div>
    </div>
  );
};

export default TodayDateTime;
