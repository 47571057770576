import React from 'react';
import './BookingEvents.scss';
import format from 'date-fns/format';
import isWithinInterval from 'date-fns/isWithinInterval';
import { Trans } from 'react-i18next';

const BookingEvents = ({ bookings, brandingColor }) => {
  const isCurrentBook = (startTime, endTime) => {
    return isWithinInterval(new Date(), {
      start: new Date(startTime),
      end: new Date(endTime),
    });
  };

  const getFormatDate = (startTime, endTime) => {
    return `${format(new Date(startTime), 'h:mm aa')} - ${format(new Date(endTime), 'h:mm aa')}`;
  };

  return (
    <div className="events">
      <div className="events__title" style={{ color: brandingColor }}>
        <Trans i18nKey="events" />
      </div>
      <div className="events__list">
        {bookings &&
          bookings.map((item, i) => (
            <div className="events__item" key={`${item.zone}${i}`}>
              {isCurrentBook(item.startTime, item.endTime) && (
                <div className={'events__item-line'} style={{ backgroundColor: brandingColor }} />
              )}
              <div className="events__time">{getFormatDate(item.startTime, item.endTime)}</div>
              <div className="events__name">{item.title}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default BookingEvents;
