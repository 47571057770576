import React from 'react';
import './FullscreenButton.scss';
import { Fullscreen } from '../icons';
import { Trans } from 'react-i18next';
import { useFullscreen } from '@inspace-org/react-fullscreen';

const FullscreenButton = () => {
  const { toggleFullscreen } = useFullscreen();
  return (
    <div className="fullscreen">
      <button type="button" className="fullscreen__button" onClick={toggleFullscreen}>
        <p className="fullscreen__text">
          <Trans i18nKey="fullscreen_btn" />
        </p>
        <Fullscreen className="fullscreen__img" />
      </button>
    </div>
  );
};

export default FullscreenButton;
