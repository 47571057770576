import React from 'react';
import './Logo.scss';

const Logo = ({ logoImg, isEnabled }) => {
  return (
    <div className="logo">
      <img
        className="logo__img"
        src={isEnabled && logoImg ? logoImg : `${process.env.PUBLIC_URL}/img/logo-black.png`}
        alt="logo"
      />
    </div>
  );
};

export default Logo;
