import {
  DEFAULT_LS_ACCESS_TOKEN_KEY,
  DEFAULT_LS_EXCHANGE_TOKEN_KEY,
  DEFAULT_LS_REFRESH_TOKEN_KEY,
  LocalApiCredentials,
} from '@inspace-org/client';

export const apiCredentials = new LocalApiCredentials({
  accessTokenKey: `PANEL${DEFAULT_LS_ACCESS_TOKEN_KEY}`,
  refreshTokenKey: `PANEL${DEFAULT_LS_REFRESH_TOKEN_KEY}`,
  exchangeTokenKey: `PANEL${DEFAULT_LS_EXCHANGE_TOKEN_KEY}`,
});
