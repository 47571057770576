import React from 'react';
import './localization';
import './styles/reset.scss';
import { Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import ReceptionPage from './pages/ReceptionPage.jsx';
import MeetingRoomPage from './pages/MeetingRoomPage';
import { DeviceAuthGuard, DeviceConnectRouter } from '@inspace-org/react-auth';
import { PATH_APP } from './routes';
import { useNavigate } from 'react-router';

function App() {
  const navigate = useNavigate();
  const handleConnection = (isSuccessful) => (device) => {
    if (isSuccessful && device) {
      if (device.isReception) {
        navigate(PATH_APP.reception);
      } else if (device.isMeetingRoom) {
        navigate(PATH_APP.meetingRoom);
      }
    } else {
      navigate(PATH_APP.root);
    }
  };

  return (
    <div className="App">
      <DeviceConnectRouter onSuccess={handleConnection(true)} onFailure={handleConnection(false)} />

      <Routes>
        <Route path={PATH_APP.root} element={<MainPage />} />
        <Route
          path={PATH_APP.reception}
          element={
            <DeviceAuthGuard redirectTo={PATH_APP.root}>
              <ReceptionPage />
            </DeviceAuthGuard>
          }
        />
        <Route
          path={PATH_APP.meetingRoom}
          element={
            <DeviceAuthGuard redirectTo={PATH_APP.root}>
              <MeetingRoomPage />
            </DeviceAuthGuard>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
