import React, { useEffect, useState } from 'react';
import './WifiInfo.scss';
import { generateQRCode } from '../services/generationQrCode';
import { Trans } from 'react-i18next';

const WifiInfo = ({ login, password, encryption, isHidden, position }) => {
  const [WifiQR, setWifiQR] = useState('');
  useEffect(() => {
    try {
      generateQRCode({
        ssid: login,
        password,
        encryption: encryption || 'WPA',
        hiddenSSID: isHidden || false,
      }).then((res) => {
        setWifiQR(res);
      });
    } catch (err) {
      console.error(err);
    }
  });
  return (
    <div className="wifi">
      <div className="wifi__container">
        <div className="wifi__item" style={{ order: position === 'right' ? 1 : 2 }}>
          <div className="wifi__info">
            <div className="wifi__list">
              <div className="wifi__title">
                <Trans i18nKey="wifi_login" />
              </div>
              <div>{login}</div>
            </div>
            <div className="wifi__list">
              <div className="wifi__title">
                <Trans i18nKey="wifi_password" />
              </div>
              <div className="wifi_password">{password}</div>
            </div>
          </div>
        </div>
        <div className={`wifi__item ${position === 'right' ? 'wifi__qr--right' : 'wifi__qr--left'}`}>
          <div className="wifi__qr">
            <img src={WifiQR} alt="wifi-qr" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WifiInfo;
