import React from 'react';

export const AppStore = (props) => {
  return (
    <svg width="161" height="52" viewBox="0 0 161 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="1" y="1" width="158.3" height="50" rx="9" stroke="black" strokeWidth="2" />
      <path
        d="M32.2743 26.6175C32.2889 25.4829 32.5903 24.3703 33.1504 23.3835C33.7105 22.3966 34.5111 21.5675 35.4778 20.9732C34.8637 20.0961 34.0536 19.3744 33.1117 18.8652C32.1699 18.356 31.1223 18.0735 30.0522 18.04C27.7694 17.8004 25.5564 19.406 24.3931 19.406C23.2074 19.406 21.4163 18.0638 19.4877 18.1035C18.2402 18.1438 17.0244 18.5065 15.9588 19.1564C14.8932 19.8063 14.0141 20.7211 13.4072 21.8118C10.7781 26.3636 12.7392 33.0533 15.2576 36.7327C16.5176 38.5344 17.9903 40.5469 19.9172 40.4756C21.8027 40.3974 22.507 39.2732 24.783 39.2732C27.0379 39.2732 27.6987 40.4756 29.6646 40.4302C31.688 40.3973 32.9627 38.6205 34.1786 36.8017C35.0839 35.518 35.7805 34.0991 36.2427 32.5978C35.0672 32.1006 34.0641 31.2684 33.3584 30.2049C32.6527 29.1415 32.2757 27.8938 32.2743 26.6175Z"
        fill="black"
      />
      <path
        d="M28.5609 15.6202C29.6641 14.2958 30.2076 12.5936 30.076 10.875C28.3905 11.052 26.8337 11.8576 25.7156 13.1311C25.1689 13.7532 24.7502 14.477 24.4834 15.2611C24.2166 16.0452 24.107 16.8741 24.1608 17.7006C25.0038 17.7093 25.8378 17.5265 26.6 17.1662C27.3622 16.8058 28.0326 16.2772 28.5609 15.6202Z"
        fill="black"
      />
      <path
        d="M56.1093 35.916H49.6746L48.1294 40.4788H45.4039L51.4987 23.5977H54.3303L60.4251 40.4788H57.6532L56.1093 35.916ZM50.3411 33.8105H55.4416L52.9272 26.4054H52.8568L50.3411 33.8105Z"
        fill="black"
      />
      <path
        d="M73.5877 34.3285C73.5877 38.1532 71.5406 40.6105 68.4514 40.6105C67.6689 40.6514 66.8906 40.4711 66.2058 40.0903C65.5209 39.7095 64.9571 39.1435 64.579 38.4572H64.5205V44.5533H61.9942V28.174H64.4396V30.2211H64.486C64.8816 29.538 65.4549 28.975 66.1449 28.5918C66.835 28.2087 67.6161 28.0198 68.405 28.0452C71.5286 28.0453 73.5877 30.5145 73.5877 34.3285ZM70.991 34.3285C70.991 31.8367 69.7033 30.1985 67.7385 30.1985C65.8083 30.1985 64.5099 31.8712 64.5099 34.3285C64.5099 36.8084 65.8083 38.4691 67.7385 38.4691C69.7033 38.4691 70.991 36.8429 70.991 34.3285Z"
        fill="black"
      />
      <path
        d="M87.134 34.3285C87.134 38.1532 85.0869 40.6104 81.9977 40.6104C81.2152 40.6514 80.4369 40.4711 79.7521 40.0903C79.0672 39.7095 78.5034 39.1435 78.1253 38.4571H78.0668V44.5532H75.5405V28.174H77.9858V30.2211H78.0323C78.4278 29.538 79.0011 28.975 79.6912 28.5918C80.3813 28.2087 81.1623 28.0198 81.9512 28.0452C85.075 28.0452 87.134 30.5145 87.134 34.3285ZM84.5373 34.3285C84.5373 31.8367 83.2496 30.1985 81.2848 30.1985C79.3546 30.1985 78.0562 31.8712 78.0562 34.3285C78.0562 36.8084 79.3546 38.4691 81.2848 38.4691C83.2496 38.4691 84.5373 36.8429 84.5373 34.3285H84.5373Z"
        fill="black"
      />
      <path
        d="M96.087 35.7755C96.2741 37.4495 97.9004 38.5487 100.123 38.5487C102.252 38.5487 103.784 37.4495 103.784 35.94C103.784 34.6297 102.86 33.8452 100.672 33.3075L98.4845 32.7805C95.3847 32.0317 93.9456 30.5821 93.9456 28.2297C93.9456 25.317 96.4839 23.3164 100.088 23.3164C103.655 23.3164 106.101 25.317 106.183 28.2297H103.633C103.48 26.545 102.087 25.5281 100.052 25.5281C98.0172 25.5281 96.6246 26.557 96.6246 28.0544C96.6246 29.2479 97.514 29.9502 99.6899 30.4878L101.55 30.9444C105.013 31.7635 106.452 33.1548 106.452 35.624C106.452 38.7823 103.937 40.7603 99.9355 40.7603C96.1918 40.7603 93.6641 38.8287 93.5009 35.7754L96.087 35.7755Z"
        fill="black"
      />
      <path
        d="M111.905 25.2578V28.1704H114.245V30.1711H111.905V36.9561C111.905 38.0102 112.373 38.5014 113.402 38.5014C113.68 38.4966 113.957 38.477 114.233 38.4429V40.4316C113.771 40.518 113.3 40.5572 112.83 40.5484C110.338 40.5484 109.366 39.6125 109.366 37.2256V30.1711H107.577V28.1704H109.366V25.2578H111.905Z"
        fill="black"
      />
      <path
        d="M115.601 34.3254C115.601 30.4529 117.881 28.0195 121.438 28.0195C125.006 28.0195 127.276 30.4529 127.276 34.3254C127.276 38.2084 125.018 40.6312 121.438 40.6312C117.859 40.6312 115.601 38.2084 115.601 34.3254ZM124.702 34.3254C124.702 31.669 123.485 30.1011 121.438 30.1011C119.391 30.1011 118.175 31.6809 118.175 34.3254C118.175 36.9924 119.391 38.5483 121.438 38.5483C123.485 38.5483 124.702 36.9924 124.702 34.3254H124.702Z"
        fill="black"
      />
      <path
        d="M129.359 28.1739H131.769V30.2687H131.827C131.99 29.6145 132.373 29.0363 132.913 28.6313C133.452 28.2264 134.114 28.0194 134.788 28.0451C135.079 28.0441 135.369 28.0757 135.653 28.1394V30.5024C135.286 30.3901 134.902 30.3385 134.518 30.3497C134.151 30.3348 133.785 30.3996 133.445 30.5394C133.106 30.6793 132.8 30.891 132.55 31.16C132.3 31.429 132.111 31.749 131.997 32.0979C131.882 32.4468 131.844 32.8164 131.885 33.1814V40.4816H129.359L129.359 28.1739Z"
        fill="black"
      />
      <path
        d="M147.301 36.8636C146.961 39.0979 144.785 40.6312 142.001 40.6312C138.421 40.6312 136.199 38.2323 136.199 34.3838C136.199 30.5233 138.433 28.0195 141.895 28.0195C145.3 28.0195 147.442 30.3587 147.442 34.0904V34.956H138.749V35.1086C138.709 35.5616 138.766 36.0179 138.915 36.4472C139.065 36.8766 139.304 37.2692 139.618 37.5989C139.931 37.9286 140.31 38.1879 140.732 38.3596C141.153 38.5313 141.605 38.6115 142.06 38.5947C142.657 38.6507 143.256 38.5124 143.768 38.2005C144.28 37.8886 144.678 37.4197 144.902 36.8636L147.301 36.8636ZM138.761 33.1903H144.914C144.937 32.783 144.875 32.3755 144.733 31.9932C144.59 31.6109 144.371 31.2622 144.087 30.9689C143.804 30.6756 143.463 30.444 143.085 30.2887C142.708 30.1335 142.303 30.0578 141.895 30.0666C141.484 30.0642 141.076 30.1432 140.695 30.2992C140.315 30.4552 139.969 30.685 139.677 30.9754C139.386 31.2658 139.155 31.6111 138.997 31.9912C138.84 32.3714 138.76 32.7789 138.761 33.1903V33.1903Z"
        fill="black"
      />
      <path
        d="M50.0244 10.8959C50.5541 10.8579 51.0855 10.9379 51.5805 11.1302C52.0755 11.3225 52.5216 11.6222 52.8868 12.0077C53.2519 12.3933 53.5269 12.8551 53.6919 13.3598C53.8569 13.8645 53.9079 14.3996 53.8411 14.9263C53.8411 17.5177 52.4406 19.0073 50.0244 19.0073H47.0945V10.8959H50.0244ZM48.3544 17.8602H49.8837C50.2622 17.8828 50.6409 17.8207 50.9924 17.6785C51.3438 17.5363 51.6592 17.3175 51.9154 17.0381C52.1716 16.7586 52.3623 16.4255 52.4736 16.0631C52.5849 15.7007 52.614 15.318 52.5587 14.9429C52.61 14.5693 52.578 14.1889 52.465 13.8292C52.352 13.4694 52.1608 13.139 51.9051 12.8618C51.6495 12.5846 51.3357 12.3673 50.9862 12.2256C50.6367 12.0839 50.2602 12.0213 49.8837 12.0422H48.3544V17.8602Z"
        fill="black"
      />
      <path
        d="M55.2642 15.9417C55.2258 15.5394 55.2718 15.1336 55.3993 14.7501C55.5269 14.3667 55.7332 14.0141 56.005 13.7151C56.2768 13.416 56.6081 13.1771 56.9777 13.0136C57.3472 12.8501 57.7469 12.7656 58.151 12.7656C58.5551 12.7656 58.9547 12.8501 59.3243 13.0136C59.6939 13.1771 60.0252 13.416 60.297 13.7151C60.5688 14.0141 60.7751 14.3667 60.9026 14.7501C61.0302 15.1336 61.0762 15.5394 61.0377 15.9417C61.0769 16.3444 61.0315 16.7508 60.9042 17.1349C60.777 17.519 60.5708 17.8722 60.299 18.1718C60.0271 18.4714 59.6955 18.7109 59.3256 18.8747C58.9557 19.0386 58.5556 19.1232 58.151 19.1232C57.7464 19.1232 57.3463 19.0386 56.9763 18.8747C56.6064 18.7109 56.2749 18.4714 56.003 18.1718C55.7311 17.8722 55.525 17.519 55.3977 17.1349C55.2705 16.7508 55.225 16.3444 55.2642 15.9417ZM59.7951 15.9417C59.7951 14.6148 59.1991 13.8389 58.153 13.8389C57.1029 13.8389 56.5122 14.6148 56.5122 15.9417C56.5122 17.2792 57.1029 18.0492 58.153 18.0492C59.1991 18.0492 59.7952 17.2739 59.7952 15.9417H59.7951Z"
        fill="black"
      />
      <path
        d="M68.7123 19.008H67.4591L66.1939 14.4997H66.0984L64.8385 19.008H63.5973L61.9099 12.8867H63.1353L64.2318 17.5576H64.3221L65.5806 12.8867H66.7396L67.9981 17.5576H68.0937L69.1849 12.8867H70.3929L68.7123 19.008Z"
        fill="black"
      />
      <path
        d="M71.8121 12.8859H72.975V13.8583H73.0653C73.2185 13.5091 73.4767 13.2163 73.8042 13.0208C74.1316 12.8253 74.5119 12.7368 74.892 12.7677C75.1899 12.7454 75.4889 12.7903 75.7671 12.8991C76.0452 13.008 76.2953 13.1781 76.4987 13.3967C76.7022 13.6154 76.8539 13.877 76.9425 14.1623C77.0312 14.4475 77.0545 14.749 77.0108 15.0445V19.0071H75.8027V15.3478C75.8027 14.3641 75.3752 13.8749 74.4818 13.8749C74.2796 13.8655 74.0777 13.8999 73.89 13.9758C73.7024 14.0517 73.5333 14.1673 73.3945 14.3146C73.2557 14.462 73.1503 14.6376 73.0857 14.8294C73.0211 15.0213 72.9987 15.2249 73.0202 15.4262V19.0072H71.8121L71.8121 12.8859Z"
        fill="black"
      />
      <path d="M78.9357 10.4961H80.1438V19.007H78.9357V10.4961Z" fill="black" />
      <path
        d="M81.8231 15.9418C81.7847 15.5395 81.8307 15.1336 81.9583 14.7502C82.0859 14.3667 82.2923 14.0141 82.5641 13.7151C82.8359 13.416 83.1672 13.1771 83.5368 13.0136C83.9064 12.8501 84.3061 12.7656 84.7102 12.7656C85.1143 12.7656 85.514 12.8501 85.8836 13.0136C86.2532 13.1771 86.5845 13.416 86.8563 13.7151C87.1282 14.0141 87.3345 14.3667 87.4621 14.7502C87.5897 15.1336 87.6357 15.5395 87.5973 15.9418C87.6365 16.3445 87.5909 16.751 87.4637 17.135C87.3364 17.5191 87.1302 17.8723 86.8583 18.1719C86.5864 18.4715 86.2548 18.711 85.8849 18.8748C85.5149 19.0387 85.1148 19.1233 84.7102 19.1233C84.3056 19.1233 83.9055 19.0387 83.5355 18.8748C83.1656 18.711 82.834 18.4715 82.5621 18.1719C82.2902 17.8723 82.084 17.5191 81.9568 17.135C81.8295 16.751 81.784 16.3445 81.8231 15.9418ZM86.354 15.9418C86.354 14.615 85.758 13.839 84.7119 13.839C83.6618 13.839 83.0711 14.615 83.0711 15.9418C83.0711 17.2793 83.6618 18.0493 84.7119 18.0493C85.758 18.0493 86.354 17.274 86.354 15.9418H86.354Z"
        fill="black"
      />
      <path
        d="M88.869 17.274C88.869 16.1721 89.6895 15.5369 91.1458 15.4466L92.8039 15.351V14.8227C92.8039 14.1761 92.3764 13.8111 91.5507 13.8111C90.8763 13.8111 90.409 14.0587 90.2749 14.4914H89.1054C89.2288 13.44 90.2178 12.7656 91.6064 12.7656C93.1411 12.7656 94.0066 13.5296 94.0066 14.8227V19.0051H92.8437V18.1448H92.7481C92.5541 18.4534 92.2817 18.705 91.9587 18.8738C91.6356 19.0427 91.2736 19.1228 90.9095 19.1059C90.6525 19.1327 90.3928 19.1052 90.1471 19.0255C89.9013 18.9457 89.6751 18.8153 89.4828 18.6427C89.2906 18.4701 89.1366 18.2591 89.0309 18.0233C88.9252 17.7876 88.8701 17.5323 88.869 17.274ZM92.8039 16.7509V16.2391L91.3091 16.3347C90.4661 16.3911 90.0837 16.6779 90.0837 17.2175C90.0837 17.7685 90.5617 18.0891 91.2188 18.0891C91.4114 18.1086 91.6059 18.0891 91.7908 18.0319C91.9757 17.9747 92.1472 17.8808 92.295 17.756C92.4429 17.6311 92.5642 17.4778 92.6516 17.3052C92.739 17.1325 92.7908 16.944 92.8039 16.7509Z"
        fill="black"
      />
      <path
        d="M95.5944 15.9437C95.5944 14.0095 96.5887 12.7841 98.1353 12.7841C98.5179 12.7665 98.8975 12.8581 99.23 13.0483C99.5624 13.2385 99.8338 13.5193 100.012 13.8581H100.103V10.4961H101.311V19.007H100.153V18.0399H100.058C99.8651 18.3763 99.5842 18.6537 99.2454 18.8419C98.9066 19.0302 98.5227 19.1221 98.1353 19.1079C96.5781 19.1079 95.5944 17.8826 95.5944 15.9437ZM96.8423 15.9437C96.8423 17.242 97.4543 18.0233 98.4779 18.0233C99.4961 18.0233 100.125 17.2308 100.125 15.949C100.125 14.6732 99.4895 13.8694 98.4779 13.8694C97.4609 13.8694 96.8423 14.656 96.8423 15.9437H96.8423Z"
        fill="black"
      />
      <path
        d="M106.309 15.9417C106.271 15.5394 106.317 15.1336 106.444 14.7501C106.572 14.3667 106.778 14.0141 107.05 13.7151C107.322 13.416 107.653 13.1771 108.022 13.0136C108.392 12.8501 108.792 12.7656 109.196 12.7656C109.6 12.7656 110 12.8501 110.369 13.0136C110.739 13.1771 111.07 13.416 111.342 13.7151C111.614 14.0141 111.82 14.3667 111.947 14.7501C112.075 15.1336 112.121 15.5394 112.083 15.9417C112.122 16.3444 112.076 16.7508 111.949 17.1349C111.822 17.519 111.616 17.8722 111.344 18.1718C111.072 18.4714 110.74 18.7109 110.37 18.8747C110 19.0386 109.6 19.1232 109.196 19.1232C108.791 19.1232 108.391 19.0386 108.021 18.8747C107.651 18.7109 107.32 18.4714 107.048 18.1718C106.776 17.8722 106.57 17.519 106.443 17.1349C106.315 16.7508 106.27 16.3444 106.309 15.9417ZM110.84 15.9417C110.84 14.6148 110.244 13.8389 109.198 13.8389C108.148 13.8389 107.557 14.6148 107.557 15.9417C107.557 17.2792 108.148 18.0492 109.198 18.0492C110.244 18.0492 110.84 17.2739 110.84 15.9417Z"
        fill="black"
      />
      <path
        d="M113.703 12.8859H114.866V13.8583H114.957C115.11 13.5091 115.368 13.2163 115.696 13.0208C116.023 12.8253 116.403 12.7368 116.783 12.7677C117.081 12.7454 117.38 12.7903 117.658 12.8991C117.937 13.008 118.187 13.1781 118.39 13.3967C118.594 13.6154 118.745 13.877 118.834 14.1623C118.922 14.4475 118.946 14.749 118.902 15.0445V19.0071H117.694V15.3478C117.694 14.3641 117.267 13.8749 116.373 13.8749C116.171 13.8655 115.969 13.8999 115.781 13.9758C115.594 14.0517 115.425 14.1673 115.286 14.3146C115.147 14.462 115.042 14.6376 114.977 14.8294C114.912 15.0213 114.89 15.2249 114.911 15.4262V19.0072H113.703V12.8859Z"
        fill="black"
      />
      <path
        d="M125.728 11.3633V12.9152H127.055V13.9327H125.728V17.0804C125.728 17.7216 125.993 18.0023 126.594 18.0023C126.748 18.0018 126.902 17.9925 127.055 17.9745V18.9807C126.838 19.0195 126.618 19.0402 126.397 19.0425C125.054 19.0425 124.519 18.5698 124.519 17.3897V13.9327H123.547V12.9151H124.519V11.3633H125.728Z"
        fill="black"
      />
      <path
        d="M128.705 10.4961H129.902V13.8694H129.998C130.158 13.5169 130.424 13.2224 130.758 13.0262C131.092 12.83 131.478 12.7415 131.864 12.7728C132.16 12.7567 132.457 12.8062 132.732 12.9177C133.007 13.0292 133.254 13.2001 133.455 13.418C133.656 13.636 133.807 13.8957 133.897 14.1787C133.986 14.4616 134.012 14.7608 133.972 15.0549V19.007H132.763V15.3529C132.763 14.3752 132.308 13.88 131.454 13.88C131.246 13.863 131.038 13.8915 130.842 13.9636C130.647 14.0358 130.469 14.1497 130.323 14.2975C130.176 14.4453 130.063 14.6235 129.992 14.8194C129.921 15.0153 129.894 15.2244 129.913 15.4319V19.007H128.705L128.705 10.4961Z"
        fill="black"
      />
      <path
        d="M141.016 17.3524C140.852 17.9118 140.497 18.3958 140.012 18.7195C139.527 19.0433 138.944 19.1862 138.364 19.1233C137.961 19.134 137.56 19.0567 137.189 18.897C136.819 18.7372 136.488 18.4987 136.219 18.1981C135.95 17.8975 135.749 17.5419 135.631 17.1561C135.514 16.7703 135.481 16.3634 135.536 15.9638C135.483 15.5629 135.516 15.1552 135.633 14.7682C135.751 14.3812 135.95 14.024 136.218 13.7208C136.485 13.4175 136.815 13.1753 137.184 13.0105C137.554 12.8458 137.954 12.7623 138.359 12.7657C140.062 12.7657 141.089 13.9294 141.089 15.8516V16.2731H136.767V16.3408C136.748 16.5655 136.776 16.7916 136.85 17.0046C136.923 17.2177 137.041 17.4131 137.194 17.5782C137.348 17.7433 137.534 17.8745 137.741 17.9634C137.948 18.0523 138.172 18.097 138.397 18.0944C138.686 18.1291 138.979 18.0771 139.238 17.945C139.497 17.8128 139.712 17.6065 139.853 17.3524L141.016 17.3524ZM136.767 15.3797H139.859C139.874 15.1742 139.846 14.9679 139.777 14.7738C139.707 14.5798 139.598 14.4025 139.456 14.2531C139.315 14.1038 139.143 13.9858 138.953 13.9067C138.763 13.8276 138.558 13.7892 138.352 13.7939C138.143 13.7913 137.936 13.8305 137.742 13.9093C137.549 13.9881 137.373 14.1048 137.225 14.2526C137.077 14.4003 136.961 14.5762 136.882 14.7698C136.803 14.9634 136.764 15.1707 136.767 15.3797H136.767Z"
        fill="black"
      />
    </svg>
  );
};
