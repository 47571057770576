import QRCode from 'qrcode';

export const generateQRCode = async ({ url, ssid, password, encryption, hiddenSSID }) => {
  const newString = url ? url : generateWifiString({ ssid, password, encryption, hiddenSSID });
  try {
    const retVal = await QRCode.toDataURL(newString);
    return retVal;
  } catch (err) {
    throw err;
  }
};

const generateWifiString = ({ ssid, password, encryption, hiddenSSID }) => {
  const ssidFormat = mecardFormat(ssid);
  const passwordFormat = mecardFormat(password);

  let retVal = `WIFI:S:${ssidFormat};P:${passwordFormat};H:${hiddenSSID};`;
  if (encryption !== 'None') {
    retVal += `T:${encryption};`;
  }
  return retVal;
};

const mecardFormat = (input) => {
  input = input.replace(/\\/g, '\\\\');
  input = input.replace(/"/g, '\\"');
  input = input.replace(/;/g, '\\;');
  input = input.replace(/,/g, '\\,');
  input = input.replace(/:/g, '\\:');
  return input;
};
