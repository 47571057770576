import React, { useContext, useEffect, useState } from 'react';
import './ReceptionPage.scss';
import WifiInfo from '../components/WifiInfo';
import { AppStore, GooglePlay } from '../icons';
import TodayDateTime from '../components/TodayDateTime';
import PeopleInOffice from '../components/PeopleInOffice';
import { api } from '../services/http';
import set from 'date-fns/set';
import MainLayout from '../components/MainLayout';
import { Trans } from 'react-i18next';
import { DeviceAuthContext } from '@inspace-org/react-auth';

const SHOW_USERS_LENGTH = 6;

const ReceptionPage = () => {
  const { settings, branding } = useContext(DeviceAuthContext);
  const [usersAllAvatar, setUsersAllAvatar] = useState([]);
  const [usersAvatar, setUsersAvatar] = useState([]);
  const [countUsers, setCountUsers] = useState(0);
  const startTime = set(new Date(), { hours: 0, minutes: 0, seconds: 0 }).toISOString();
  const endTime = set(new Date(), { hours: 23, minutes: 59, seconds: 59 }).toISOString();

  const updateArrayRandomElem = (arr, maxLength) => {
    if (!arr.length) return null;
    const newRandomAvatarsArr = [];
    while (newRandomAvatarsArr.length < SHOW_USERS_LENGTH) {
      const rand = Math.floor(Math.random() * arr.length);
      if (!newRandomAvatarsArr.includes(arr[rand])) {
        newRandomAvatarsArr.push(arr[rand]);
      }
    }
    return newRandomAvatarsArr;
  };

  const getUsersAvatar = async () => {
    try {
      await api
        .get('/v2/dashboard', {
          'dates[0][startTime]': startTime,
          'dates[0][endTime]': endTime,
          requireAvatars: true,
        })
        .then((res) => {
          setCountUsers(res.usersInBookings.count);
          api
            .get('/v2/dashboard', {
              'dates[0][startTime]': startTime,
              'dates[0][endTime]': endTime,
              usersInBookings: res.usersInBookings.count,
              requireAvatars: true,
            })
            .then((res) => {
              const avatarsArr = res.usersInBookings.list.map((el) => el.avatar);
              setUsersAllAvatar(avatarsArr);
              if (avatarsArr.length > SHOW_USERS_LENGTH) {
                setUsersAvatar(updateArrayRandomElem(avatarsArr, SHOW_USERS_LENGTH));
              } else {
                setUsersAvatar(avatarsArr);
              }
            });
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    let interval;
    if (usersAllAvatar.length > SHOW_USERS_LENGTH) {
      interval = setInterval(() => {
        setUsersAvatar(updateArrayRandomElem(usersAllAvatar, SHOW_USERS_LENGTH));
      }, 30000);
    } else {
      setUsersAvatar(usersAllAvatar);
    }
    return () => clearInterval(interval);
  });

  useEffect(() => {
    const interval = setInterval(() => {
      getUsersAvatar();
    }, 60000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    getUsersAvatar();
  }, []);

  return (
    <MainLayout
      isEnabled={branding.isEnabled}
      logoImg={branding.fullLogo}
      type={'reception'}
      bgImage={settings.panel.bgImage}
      childrenLeft={
        <div className="reception">
          <div className="reception__title">
            <p>{settings?.welcomeText?.text}</p>
          </div>
          <div className="apps">
            <div className="apps__qr">
              <img src={`${process.env.PUBLIC_URL}/img/appsQr.png`} className="apps__qr--img" />
              <p className="apps__title">
                <Trans i18nKey="get_app" />
              </p>
            </div>
            <ul className="apps__list">
              <li className="apps__item">
                <a
                  href="https://apps.apple.com/us/app/inspace-app/id1569382208"
                  target="_blank"
                  rel="noreferrer"
                  className="apps__link"
                >
                  <AppStore className="apps__apple" />
                </a>
              </li>
              <li className="apps__item">
                <a
                  href="https://play.google.com/store/apps/details?id=app.inspace&hl=en_US&gl=US"
                  target="_blank"
                  rel="noreferrer"
                  className="apps__link"
                >
                  <GooglePlay className="apps__google" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      }
      childrenRight={
        <>
          <TodayDateTime />
          <div className="section">
            <PeopleInOffice usersAvatar={usersAvatar} countUsers={countUsers} />
            {settings.wifi?.ssid && (
              <WifiInfo
                position="right"
                login={settings.wifi.ssid}
                password={settings.wifi.password}
                encryption={settings.wifi.encryption}
                isHidden={settings.wifi.isHidden}
              />
            )}
          </div>
          <div></div>
        </>
      }
    />
  );
};

export default ReceptionPage;
