import React from 'react';
import './index.scss';
import App from './App';
import ReactDOM from 'react-dom/client';
import { apiCredentials, apiUrl } from './services/http';
import { language } from './localization';
import { DeviceAuthContextProvider } from '@inspace-org/react-auth';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <DeviceAuthContextProvider apiUrl={apiUrl} credentials={apiCredentials} language={language}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </DeviceAuthContextProvider>,
);
