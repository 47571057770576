import { useContext, useEffect } from 'react';
import { DeviceAuthContext } from '@inspace-org/react-auth';
import { useNavigate } from 'react-router';
import { PATH_APP } from '../routes';

const MainPage = () => {
  const navigate = useNavigate();
  const { isInitialized, isAuthenticated, device } = useContext(DeviceAuthContext);

  useEffect(() => {
    if (isInitialized && isAuthenticated) {
      if (device.isReception) {
        return navigate(PATH_APP.reception, { replace: true });
      }
      if (device.isMeetingRoom) {
        return navigate(PATH_APP.meetingRoom, { replace: true });
      }
    }
  }, [isInitialized, isAuthenticated, device]);

  return (
    <section>
      <p>
        Please, open this page from your settings at
        <br />
        <a href="https://admin.inspace.app">https://admin.inspace.app</a>
      </p>
    </section>
  );
};

export default MainPage;
